<script>
export default {
  name: "ImageUploadShow",
  props: {
    prompt: {
      type: String,
      default(){
        return ''
      }
    },
    img: {
      type: String,
      default(){
        return ''
      }
    },
    showDeleteIcon: {
      type: Boolean,
      default(){
        return false
      }
    }
  },
  data(){
    return {

    }
  },
  computed: {
    hasImg(){
      return !!this.img.slice(5)
    },
    showDeleteIconInner(){
      return this.showDeleteIcon && this.hasImg
    }
  },
  methods: {
    deleteIconHandler(){
      this.$emit('deleteIcon')
    },
  }
}
</script>

<template>
  <div class="image-upload-show-container">
    <div v-if="hasImg" class="image-container"  >
      <img :src="img" alt="">
    </div>
    <div v-else class="empty-status">
      <div>
        <i class="el-icon-plus"></i>
      </div>
      <div class="prompt">{{ prompt }}</div>
    </div>
    <div v-if="showDeleteIconInner"
         class="delete-icon"
         @click.stop="deleteIconHandler">
      <img src="@/assets/icon/tips.png" alt="">
    </div>
  </div>
</template>

<style scoped lang="scss">
.image-upload-show-container{
  position: relative;
  padding: 8px;
  width: 128px;
  height: 128px;
  background-color: #F5F7FA;
  border: 1px dotted #CED4DB;
  .image-container{
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .empty-status{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
    .el-icon-plus{
      color: #2F3133;
      font-size: 22px;
    }
    .prompt{
      margin-top: -8px;
      font-size: 12px;
      color: #8D9094;
    }
  }
  .delete-icon{
    position: absolute;
    right: 12px;
    top: 12px;
    width: 20px;
    height: 20px;
    img{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>