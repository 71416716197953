<template>
  <div class="page-container">
    <page-header></page-header>
    <div class="dashboard-container">
      <div class="page-card">
        <div class="page-card-header">
          <span style="font-weight: bold;">{{ $route.meta.title }}</span>
        </div>
        <div class="page-card-body">
          <el-form :model="pageForm" :rules="pageRules" label-width="120px"
                   ref="pageFormRef" inline label-position="top"
                   v-loading="page_form_loading">
            <el-row>
              <el-col :span="5">
                <el-form-item label="姓名" prop="name" style="width: 100%;">
                  <el-input  placeholder="姓名" v-model="pageForm.name" maxlength="20"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5" :offset="1">
                <el-form-item label="电话号码" prop="phone" style="width: 100%;">
                  <el-input placeholder="电话号码" v-model="pageForm.phone" maxlength="11"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5" :offset="1">
                <el-form-item label="身份证号" prop="idNo" style="width: 100%;">
                  <el-input placeholder="身份证号" v-model="pageForm.idNo" maxlength="20"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5" :offset="1">
                <el-form-item label="开户行" prop="bankName" style="width: 100%;">
                  <el-input placeholder="开户行" v-model="pageForm.bankName" maxlength="256"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="5">
                <el-form-item label="银行卡号" prop="bankNo" style="width: 100%;">
                  <el-input placeholder="银行卡号" v-model="pageForm.bankNo" maxlength="20"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5" :offset="1">
                <el-form-item label="所在地区" prop="areas" style="width: 100%;">
                  <el-cascader ref="areaCascaderRef"
                               style="width: 100%;"
                               v-model="pageForm.areas"
                               :options="pca_options"
                               @change="areaChange"
                               :props="{ checkStrictly: false , label: 'name', value: 'name' ,children: 'children' }"></el-cascader>
                </el-form-item>
              </el-col>
              <el-col :span="5" :offset="1">
                <el-form-item label="详细地址" prop="address" style="width: 100%;">
                  <el-input v-model="pageForm.address" placeholder="详细地址"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5" :offset="1"></el-col>
            </el-row>

            <el-row>
              <el-col :span="5" >
                <el-form-item label="身份证照片" prop="idNoFrontImg">
                  <div style="display: flex;">
                    <div>
                      <el-upload
                          class="upload-demo"
                          action="-"
                          :show-file-list="false"
                          :http-request="upLoadImage('idNoFrontImg')">
                        <image-upload-show show-delete-icon prompt="身份证正面"
                                           :img="getSourceUrl(pageForm.idNoFrontImg)"
                                           @deleteIcon="pageForm.idNoFrontImg = ''"></image-upload-show>
                      </el-upload>
                    </div>
                    <div style="margin-left: 10px;">
                      <el-upload
                          class="upload-demo"
                          action="-"
                          :show-file-list="false"
                          :http-request="upLoadImage('idNoOppositeImg')">
                        <image-upload-show show-delete-icon prompt="身份证反面"
                                           :img="getSourceUrl(pageForm.idNoOppositeImg)"
                                           @deleteIcon="pageForm.idNoOppositeImg = ''"></image-upload-show>
                      </el-upload>
                    </div>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="5" :offset="1">
                <el-form-item label="银行卡照片" prop="bankNoImg">
                  <div>
                    <el-upload
                        class="upload-demo"
                        action="-"
                        :show-file-list="false"
                        :http-request="upLoadImage('bankNoImg')">
                      <image-upload-show show-delete-icon prompt="银行卡照片"
                                         :img="getSourceUrl(pageForm.bankNoImg)"
                                         @deleteIcon="pageForm.bankNoImg = ''"></image-upload-show>
                    </el-upload>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="5" :offset="1">
                <el-form-item label="房产证照片" prop="houseCertificateImg">
                  <div>
                    <el-upload
                        class="upload-demo"
                        action="-"
                        :show-file-list="false"
                        :http-request="upLoadImage('houseCertificateImg')">
                      <image-upload-show show-delete-icon prompt="房产证照片"
                                         :img="getSourceUrl(pageForm.houseCertificateImg)"
                                         @deleteIcon="pageForm.houseCertificateImg = ''"></image-upload-show>
                    </el-upload>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="5" :offset="1"></el-col>
            </el-row>

            <el-row>
              <el-col :span="5">
                <el-form-item label="备注" prop="remarks" style="width: 100%;">
                  <el-input v-model="pageForm.remarks" type="textarea" placeholder="备注" ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5" :offset="1"></el-col>
            </el-row>

            <div class="dialog-footer">
              <el-button @click="cancel">取 消</el-button>
              <el-button @click="submit('pageFormRef')" type="primary">确 定</el-button>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import {FARMER_TYPES, PROVINCE_CODES} from "@/utils/config";
import pcaCode from "@/utils/pca-code";
import {arouseCropping, base64ToFile, fileToDataURL} from "@/utils/imageCropper";
import {farmerCreate, farmerInfo, farmerUpdate, uploadFile} from "@/api/common";
import helper from "@/utils/helper";
import ImageUploadShow from "@/components/ImageUploadShow.vue";
export default {
  name: 'FarmerEdit',
  components: {ImageUploadShow, PageHeader},
  data(){
    const validateOptionList = (rule, value, callback) => {
      if (!this.pageForm.idNoFrontImg) {
        callback(new Error('请上传身份证照片'))
      }
      if (!this.pageForm.idNoOppositeImg){
        callback(new Error('请上传身份证照片'))
      }
      callback()
    }
    return {
      id: null,
      farmerTypeOptions: FARMER_TYPES,
      pca_options: pcaCode,
      page_form_loading: false,
      pageForm: {
        name: '',
        idNo: '',
        phone: '',
        provinceCode: '',
        province: '',
        city: '',
        area: '',
        areas: [],
        address: '',
        remarks: '',
        // --- 房产证图片
        houseCertificateImg: '',
        // 身份证人像图片
        idNoFrontImg: '',
        // 身份证国徽图片
        idNoOppositeImg: '',
        // 开户行
        bankName: '',
        // 银行卡号
        bankNo: '',
        // 银行卡号图片
        bankNoImg: '',

      },
      pageRules: {
        name: [
          {required: true, message: '请输入名称', trigger: 'blur'},
        ],
        idNo: [
          {required: true, message: '请输入身份证', trigger: 'blur'},
        ],
        idNoFrontImg: [
          {required: true, message: '请上传身份证照片', trigger: 'submit'},
          {validator: validateOptionList, trigger: 'submit'},
        ],
        bankName: [
          {required: true, message: '请输入开户行', trigger: 'blur'},
        ],
        bankNo: [
          {required: true, message: '请输入银行卡号', trigger: 'blur'},
        ],
        bankNoImg: [
          {required: true, message: '请上传银行卡照片', trigger: 'submit'},
        ],
        phone: [
          {required: true, message: '请输入电话', trigger: 'blur'},
        ],
        areas: [
          {required: true, message: '请选择地址', trigger: 'blur'},
        ],
        address: [
          {required: true, message: '请输入详细地址', trigger: 'blur'},
        ],
      },
    }
  },
  mounted() {
    this.id = this.$route.params.id
    if (this.id) {
      this.getFarmerInfo()
    }
  },
  methods: {
    getSourceUrl: helper.getSourceUri,
    /**
     * 取消
     */
    cancel(){
      this.$router.replace('/admin/service-farmer')
    },
    /**
     * 提交
     */
    submit(ref){
      let that = this
      that.$refs[ref].validate((valid) => {
        if (!valid) {
          return false
        }
        that.page_form_loading = true
        let func = farmerUpdate
        if (!that.id) {
          func = farmerCreate
        }
        func(that.pageForm).then(() => {
          that.page_form_loading = false
          that.$message.success('提交成功')
          that.page_dialog_visible = false
          setTimeout(() => {
            that.$router.replace('/admin/service-farmer')
          }, 500)
        }).catch(() => {
          that.page_form_loading = false
        })
      })
    },
    /**
     * 查询
     */
    getFarmerInfo(){
      farmerInfo(this.id).then(res => {
        const {
          name, idNo,
          phone, provinceCode, province,
          city, area,
          address, remark,
          idNoFrontImg,
          idNoOppositeImg, bankNo,remarks,
          bankNoImg, houseCertificateImg,
          version, id, bankName
        } = res
        this.pageForm = {
          name, idNo,
          phone, provinceCode, province,
          city, area,
          address, remark,
          idNoFrontImg, remarks,
          idNoOppositeImg, bankNo,
          areas: [province, city, area],
          bankNoImg, houseCertificateImg,
          version, id, bankName
        }
      })
    },
    /**
     * 选择图片
     * @param key
     */
    upLoadImage(key){
      return (file) => {
        fileToDataURL(file.file,
            img => arouseCropping({ img, fixedNumber: [1.58, 1], fixed: key !== 'houseCertificateImg' },
                e => {
                  uploadFile(base64ToFile(e)).then(res => {
                    this.pageForm[key] = res
                  })
                }
            )
        )
      }
    },
    areaChange(val) {
      if (!val) {
        return
      }
      this.pageForm.province = val[0]
      this.pageForm.city = val[1]
      this.pageForm.area = val[2]
      let company = PROVINCE_CODES.find(item => {
        return item.province == val[0]
      })
      if (company) {
        this.pageForm.provinceCode = company.id
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.page-container{
  .dashboard-container{
    .page-card{
      .page-card-header{
        padding-bottom: 0;
        flex-direction: column;
        align-items: flex-start;
        border-bottom: none;
        &::after{
          padding-top: 20px;
          content: ' ';
          width: 100%;
          height: 1px;
          border-bottom: 1px solid #ebeef5;
        }
      }
    }
  }
}

.page-card-body {
  padding: 20px;

  .table-action-item {
    display: inline-block;
    cursor: pointer;
    color: $--color-primary;

    & + .table-action-item {
      margin-left: 10px;
    }
  }
}
.id-no-card-outer{
  width: 160px;
  text-align: center;
  .id-no-card{
    width: 100%;
    height: 90px;
    border-radius: 16px;
    background-color: #f6f6f6;
    overflow: hidden;
    img{
      width: 100%;
      height: 100%;
    }
    .el-icon-plus{
      font-size: 40px;
      color: #666666;
      line-height: 90px;
    }
  }
  .tips-label{
    color: #666666;
  }
}
</style>